@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0c0c0c;
  color: #fff;
  overflow-y: scroll;
  /* background-color: #0c0c0c;
opacity: 0.8;
background-image: radial-gradient(#0074C2 0.5px, #0c0c0c 0.5px);
background-size: 10px 10px; */
}

a {
  color: #fff;
  text-decoration: none;
}

p {
  font-family: 'Poppins';
  font-size: 18px;
  max-width: 600px;
  font-weight: 600;
}

.wrapper {
  display: flex;
  /* align-items: center; */
  /* min-height: 100vh; */
  justify-content: center;
  text-align: center;
}

.question-ctn {
  width: 100%;
  padding: 0 20px;
  text-align: center;
  display: flex;
  align-items: center;
  margin-top: 120px;
  /* justify-content: center; */
  flex-direction: column;
  z-index: 99;
  /* background-color: red; */
}

.logo {
  height: 100px;
  /* margin-top: 100px; */
}

.question-text {
  font-size: 60px;
  font-weight: 700;
  margin: 20px 0 30px;
  display: flex;
}

.question-text span {
  letter-spacing: 0.05em;
}

.reveal-button {
  font-size: 16px;
  padding: 10px 40px;
  font-family: 'Poppins';
  border: 1px solid #0074c2;
  cursor: pointer;
  user-select: none;
  transition: all ease-in-out 200ms;
  border-radius: 2px;
}

.reveal-button:hover {
  background-color: #0074c2;
}

.no {
  font-size: 100px;
  color: #00abe7;
  line-height: 1em;
}

.lol {
  font-size: 16px;
}

.answer {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  background-color: #0c0c0c;
  padding-top: 50px;
  /* padding-bottom: 100px; */
  margin-bottom: 100px;
}

.no-way {
  font-size: 70px;
  margin: 0;
  background: #0074c2;
  padding: 5px 0;
  width: 100%;
  max-width: 1000px;
  border-radius: 5px 5px 0 0;
}

.answer-p {
  margin: 40px 0;
}

.price-section {
  background: #11161d;
  /* background: #0074c2; */
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 90px;
}

.price-row {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.price-row:last-child {
  margin-bottom: 0px;
}

.price-row p {
  margin: 0;
  margin-bottom: 5px;
  opacity: 0.4;
}

.price {
  font-weight: 700;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.percentage-change {
  font-size: 16px;
  margin-left: 10px;
  color: #10b981;
  display: flex;
  align-items: center;
}

.arrow-up2 {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
  margin-right: 2px;
}

.percentage {
  padding: 5px 10px;
  background: #10b981;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.percentage-label {
  font-size: 16px;
}

.emoji {
  width: 30px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #fff;
  margin-right: 2px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  margin-right: 2px;
}

.coming-soon-ctn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  margin-bottom: 20px;
}

.modal-ctn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.modal-ctn-col {
  width: 50%;
}

.modal-ctn-col h4 {
  margin: 0;
  margin-bottom: 10px;
}

.modal-border {
  /* width: 40%; */
  max-width: 700px;
  z-index: 9999;
  background-color: #ff21d0;
  /* background-image: linear-gradient(-151deg, #00abe7, #0074c2 53%, #00abe7); */
  padding: 1px;
  text-align: left;
  font-size: 16px;
  clip-path: polygon(100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px), 0 0);
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin: 0 10px;
}

.modal-border2 {
  background-color: #0074c2;
}

.modal-inner {
  width: 100%;
  padding: 30px;
  background-color: #0c0c0c;
  /* background-image: linear-gradient(180deg, #ff21d0, rgba(255, 0, 0, 0)); */
  clip-path: polygon(100% 0, 100% 100%, 30px 100%, 0 calc(100% - 30px), 0 0);
}

.modal-row {
  margin-bottom: 25px;
}

.modal-row:last-child {
  margin-bottom: 0px;
}

.show-more-ctn {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.show-more {
  border: 1px solid #fff;
  font-size: 12px;
  padding: 5px 20px;
  cursor: pointer;
  display: inline-block;
  opacity: 0.4;
  transition: all 200ms ease-in-out;
}

.show-more:hover {
  opacity: 1;
}

.modal-row h2 {
  margin: 0;
  font-size: 18px;
  margin-bottom: 2px;
}

.modal-row div {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}

.nope {
  width: 280px;
  margin: 0 20px;
}

h3 {
  font-size: 25px;
  margin: 0px;
  /* padding: 0 0 20px 0;
  border-bottom: 1px solid #ff21d0; */
}

.telegram-p {
  margin: 20px 0;
}

.social-ctn {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.telegram {
  width: 70px;
  transition: all 200ms ease-in-out;
  margin: 0 10px;
}

.telegram:hover {
  transform: scale(1.2);
}

.gif-ctn {
  display: flex;
  align-items: center;
}

.rocket-ctn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
}

.rocket-gif {
  width: 25%;
  padding: 10px;
}

/************************************************ GLITCH *****************************************************************/

:root {
  --f-size: 15;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #181717;
}

.glitch {
  flex: 1;
  line-height: 0.75;
  margin: auto 10px;
  color: #1af0dc;
  color: #0074c2;
  color: #fff;
  text-align: center;
  transform: scaleX(var(--scale, 1));
  animation: glitch-p 11s infinite alternate;
}
.glitch::before,
.glitch::after {
  --top: 0;
  --left: 0;
  --v-height: 30%;
  --n-tenth: calc(var(--f-size) * 0.1 * var(--top));
  --t-cut: calc(var(--n-tenth) / var(--f-size) * 100%);
  --b-cut: calc(var(--t-cut) + var(--v-height));
  content: attr(data-text);
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  transform: translateX(calc(var(--left) * 100%));
  filter: drop-shadow(0 0 transparent);
  text-shadow: calc(var(--left) * -3em) 0 0.02em lime,
    calc(var(--left) * -6em) 0 0.02em #ff00e1;
  background-color: var(--bg);
  clip-path: polygon(
    0% var(--t-cut),
    100% var(--t-cut),
    100% var(--b-cut),
    0% var(--b-cut)
  );
}
.glitch::before {
  animation: glitch-b 1.7s infinite alternate-reverse;
}
.glitch::after {
  animation: glitch-a 3.1s infinite alternate;
}
@keyframes glitch-p {
  17% {
    --scale: 0.87;
  }
  31% {
    --scale: 1.1;
  }
  37% {
    --scale: 1;
  }
  47% {
    --scale: 0.91;
  }
  87% {
    --scale: 1;
  }
}
@keyframes glitch-a {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
  }
  20% {
    --left: 0.005;
  }
  40% {
    --left: 0.01;
    --v-height: 20%;
    --top: 3;
  }
  60% {
    --left: 0.03;
    --v-height: 25%;
    --top: 6;
  }
  80% {
    --left: 0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: 0.083;
    --v-height: 30%;
    --top: 1;
  }
}
@keyframes glitch-b {
  10%,
  30%,
  50%,
  70%,
  90% {
    --top: 0;
    --left: 0;
  }
  0% {
    --v-height: 15%;
    --top: 10;
  }
  20% {
    --left: -0.005;
  }
  40% {
    --left: -0.01;
    --v-height: 17%;
    --top: 3;
  }
  60% {
    --left: -0.03;
    --v-height: 35%;
    --top: 6;
  }
  80% {
    --left: -0.07;
    --v-height: 5%;
    --top: 8;
  }
  100% {
    --left: -0.083;
    --v-height: 30%;
    --top: 1;
  }
}

/*********************************** HOVER GLOW BUTTON *****************************************/

.glow-on-hover {
  /* width: 220px;
  height: 50px; */
  /* line-height: 50px; */
  border: none;
  outline: none;
  color: #fff;
  /* background: #111; */
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: 16px;
  padding: 10px 40px;
  font-family: 'Poppins';
  user-select: none;
  border-radius: 2px;
  /* box-shadow: 0px 4px rgba(255, 255, 255, .7); */
  transition: all 200ms ease-in-out;
}

.glow-on-hover:hover {
  /* box-shadow: 0px 8px rgba(255, 255, 255, .7); */
  transform: translateY(-2px);
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #0074c2, #ff21d0);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  /* filter: blur(5px); */
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  /* opacity: 0; */
  transition: opacity 0.3s ease-in-out;
  border-radius: 3px;
}

.glow-on-hover:hover:before {
  content: '';
  background: linear-gradient(45deg, #ff21d0, #ff21d0);
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/*********************************** MEDIA *****************************************/

@media (max-width: 800px) {
  .question-ctn {
    padding: 0 10px;
    margin-top: 80px;
  }
  .logo {
    height: 70px;
    margin-top: 0px;
  }
  .question-text {
    font-size: 36px;
    font-weight: 700;
    margin: 10px 0 30px;
  }
  .glitch {
    margin: auto 3px;
  }

  .dance {
    display: none;
  }

  .no-way {
    font-size: 45px;
  }

  .price {
    font-weight: 700;
    font-size: 20px;
    flex-direction: column;
  }

  .price-row p {
    font-size: 14px;
  }

  .emoji {
    display: none;
  }

  .modal-ctn {
    flex-direction: column;
  }

  .modal-ctn-col {
    width: 100%;
  }

  .modal-ctn-col:last-child {
    margin-top: 30px;
  }

  p {
    font-size: 16px;
  }

  .rocket-ctn img:first-child,
  .rocket-ctn img:last-child {
    display: none;
  }

  .rocket-gif {
    width: 50%;
    padding: 0px;
  }

  .rocket-ctn {
    margin-bottom: 50px;
  }

  .coming-soon-ctn {
    margin-bottom: 0px;
  }

  .sitelink-ctn {
    margin-top: 0px;
  }

  .sitelink-tile {
    width: 80%;
  }

  .telegram {
    width: 50px;
  }
}

/*********************************** SCROLL *****************************************/

.dead-gif {
  width: 250px;
  margin-top: 40px;
}

/*********************************** BACKGROUND ANIMATION *****************************************/

.main-ctn {
  width: 100%;
  min-height: 100vh;
}

.main-ctn {
  background-color: #0c0c0c;
  background-size: 120px 205px;
}

@media (prefers-reduced-motion) {
  body {
    animation: none;
  }
}

.drumroll2 {
  position: absolute;
  visibility: hidden;
  height: 0;
}

.banner {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0074c2;
  text-align: center;
  padding: 8px 20px;
  font-size: 14px;
  transition: all ease-in-out 200ms;
}

.banner:hover {
  background-color: #ff21d0;
}
