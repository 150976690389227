.sitelink-ctn {
  /* background-color: #000818; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 60px;
  padding: 0 20px;
  box-sizing: border-box;
  padding: 30px 20px 130px;
}

.sitelink-ctn h3 {
  margin-bottom: 30px;
}

.sitelink-inner-ctn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  flex-wrap: wrap;
}

.sitelink-tile {
  border: 1px solid #0074c2;
  display: flex;
  flex-direction: column;
  width: 200px;
  transition: all ease-in-out 300ms;
  margin: 0 8px;
  margin-bottom: 16px;
}

.sitelink-tile:hover {
  border: 1px solid #ff21d0;
  transform: translateY(-5px);
}

.sitelink-tile img {
  width: 100%;
}

.tile-text {
  background-color: #0074c2;
  padding: 10px 15px;
  color: #fff;
  text-align: center;
}

.sitelink-tile:hover .tile-text {
  background-color: #ff21d0;
}

.tile-text h4 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.tile-text p {
  font-size: 12px;
  margin: 0;
}

/*********************************** MEDIA *****************************************/

@media (max-width: 800px) {
  .sitelink-ctn {
    padding: 60px 0;
  }

  .sitelink-inner-ctn {
    flex-direction: column;
    max-width: unset;
  }

  .sitelink-tile {
    width: 50%;
    margin-bottom: 20px;
  }
}
